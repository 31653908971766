var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "rootVM" } }, [
    _vm._m(0),
    _c("div", { staticClass: "panel panel-flat" }, [
      _c(
        "div",
        {
          staticClass: "panel-body",
          staticStyle: {
            width: "870px",
            margin: "20px 0px 0px auto",
            "padding-left": "0px",
            "padding-bottom": "0px"
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-3", staticStyle: { width: "300px" } },
              [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticStyle: { width: "100px" } }, [
                      _vm._v(_vm._s(_vm.detailsFieldMap.searchSite))
                    ]),
                    _c(
                      "select2",
                      {
                        attrs: {
                          options: _vm.options.siteOptions,
                          disabled: _vm.isSiteDisabled
                        },
                        on: { input: _vm.searchSiteInfo },
                        model: {
                          value: _vm.searchSite,
                          callback: function($$v) {
                            _vm.searchSite = $$v
                          },
                          expression: "searchSite"
                        }
                      },
                      [_c("option", { attrs: { value: "" } }, [_vm._v("선택")])]
                    )
                  ],
                  1
                )
              ]
            )
          ])
        ]
      )
    ]),
    _c("div", { staticClass: "wrapper" }, [
      _vm._m(1),
      _c("div", { staticClass: "table-wrapper", attrs: { warnWork: "" } }, [
        _c("table", { staticClass: "table table-hover" }, [
          _c("thead", [
            _c(
              "tr",
              [
                _c("th", { attrs: { width: "50px" } }, [_vm._v("주의 작업")]),
                _vm._l(_vm.cctvInfo, function(row, index) {
                  return _c("th", { key: index, attrs: { width: "120px" } }, [
                    _vm._v(" " + _vm._s(row.cctvNm) + "  "),
                    _c("input", {
                      attrs: { type: "checkbox", id: "warn_box" + row.cctvId },
                      on: {
                        input: function($event) {
                          return _vm.checkValueCctvWarnworkAllCheckBox(
                            row.cctvId,
                            $event
                          )
                        }
                      }
                    })
                  ])
                })
              ],
              2
            )
          ]),
          _c(
            "tbody",
            _vm._l(_vm.aiConfigWarnWorkCctv, function(row, index) {
              return _c(
                "tr",
                { key: index },
                [
                  _c("td", { staticStyle: { "text-align": "left" } }, [
                    _vm._v(_vm._s(row.objectNm))
                  ]),
                  _vm._l(row.aiConfigCctvCheckVOs, function(r, i) {
                    return _c("td", { key: i }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: r.checked,
                            expression: "r.checked"
                          }
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(r.checked)
                            ? _vm._i(r.checked, null) > -1
                            : r.checked
                        },
                        on: {
                          change: function($event) {
                            var $$a = r.checked,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(r, "checked", $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    r,
                                    "checked",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(r, "checked", $$c)
                            }
                          }
                        }
                      })
                    ])
                  })
                ],
                2
              )
            }),
            0
          )
        ])
      ]),
      _c("div", { staticClass: "mb-10 mt-10 text-right" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-labeled bg-primary",
            on: { click: _vm.saveAiConfigWarnWorkCctv }
          },
          [_vm._m(2), _vm._v(" 적용 ")]
        )
      ])
    ]),
    _c("div", { staticClass: "wrapper" }, [
      _vm._m(3),
      _c("div", { staticClass: "table-wrapper", attrs: { danger: "" } }, [
        _c("table", { staticClass: "table table-hover" }, [
          _c("thead", [
            _c(
              "tr",
              [
                _c("th", { attrs: { width: "50px" } }, [_vm._v("대상물")]),
                _vm._l(_vm.cctvInfo, function(row, index) {
                  return _c("th", { key: index, attrs: { width: "120px" } }, [
                    _vm._v(" " + _vm._s(row.cctvNm) + "  "),
                    _c("input", {
                      attrs: {
                        type: "checkbox",
                        id: "danger_box" + row.cctvId
                      },
                      on: {
                        input: function($event) {
                          return _vm.checkValueCctvDangerAllCheckBox(
                            row.cctvId,
                            $event
                          )
                        }
                      }
                    })
                  ])
                })
              ],
              2
            )
          ]),
          _c(
            "tbody",
            _vm._l(_vm.aiConfigDangerCctv, function(row, index) {
              return _c(
                "tr",
                { key: index },
                [
                  _c("td", { staticStyle: { "text-align": "left" } }, [
                    _vm._v(_vm._s(row.objectNm))
                  ]),
                  _vm._l(row.aiConfigCctvCheckVOs, function(r, i) {
                    return _c("td", { key: i }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: r.checked,
                            expression: "r.checked"
                          }
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(r.checked)
                            ? _vm._i(r.checked, null) > -1
                            : r.checked
                        },
                        on: {
                          change: function($event) {
                            var $$a = r.checked,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(r, "checked", $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    r,
                                    "checked",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(r, "checked", $$c)
                            }
                          }
                        }
                      })
                    ])
                  })
                ],
                2
              )
            }),
            0
          )
        ])
      ]),
      _c("div", { staticClass: "mb-10 mt-10 text-right" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-labeled bg-primary",
            on: { click: _vm.saveAiConfigDangerCctv }
          },
          [_vm._m(4), _vm._v(" 적용 ")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "pageTitle" } }, [
      _c("b", [_vm._v("AI-CCTV설정")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h5",
      {
        staticClass: "panel-title",
        staticStyle: { "padding-bottom": "10px", "padding-top": "50px" }
      },
      [_c("b", [_vm._v("주의 작업 설정")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [_c("i", { staticClass: "icon-checkmark3" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h5",
      { staticClass: "panel-title", staticStyle: { "padding-bottom": "10px" } },
      [_c("b", [_vm._v("위험 작업 설정")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [_c("i", { staticClass: "icon-checkmark3" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }