<template>
	<!-- 전체 -->
	<div id="rootVM" v-cloak>
		<div id="pageTitle">
			<b>AI-CCTV설정</b>
		</div>
		<!-- 현장 조회 -->
		<div class="panel panel-flat">
			<!-- <div class="panel-heading">
				<h6 class="panel-title">
					<i class="icon-search4 position-left"></i>
					<b>검색조건</b>
				</h6>
			</div> -->
			<div class="panel-body" style="width: 870px; margin: 20px 0px 0px auto; padding-left: 0px; padding-bottom: 0px">
				<div class="row">
					<!-- 조회할 현장 -->
					<div class="col-md-3" style="width: 300px">
						<div class="form-group">
							<label style="width: 100px">{{ detailsFieldMap.searchSite }}</label>
							<select2 v-model="searchSite" :options="options.siteOptions" :disabled="isSiteDisabled" @input="searchSiteInfo">
								<option value="">선택</option>
							</select2>
						</div>
					</div>
					<!-- 조회 버튼 -->
					<!-- <div class="mb-10 text-right">
						<button @click="searchSiteInfo" class="btn btn-labeled bg-primary">
							<b><i class="icon-search4"></i></b>
							조회
						</button>
					</div> -->
				</div>
			</div>
		</div>
		<!-- // 현장 조회 -->

		<!-- 주의 작업 -->
		<div class="wrapper">
			<h5 class="panel-title" style="padding-bottom: 10px; padding-top: 50px"><b>주의 작업 설정</b></h5>
			<div class="table-wrapper" warnWork>
				<table class="table table-hover">
					<thead>
						<tr>
							<th width="50px">주의 작업</th>
							<th width="120px" v-for="(row, index) in cctvInfo" :key="index">
								{{ row.cctvNm }}&nbsp;
								<input type="checkbox" :id="'warn_box' + row.cctvId" @input="checkValueCctvWarnworkAllCheckBox(row.cctvId, $event)" />
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(row, index) in aiConfigWarnWorkCctv" :key="index">
							<td style="text-align: left">{{ row.objectNm }}</td>
							<td v-for="(r, i) in row.aiConfigCctvCheckVOs" :key="i">
								<input type="checkbox" v-model="r.checked" />
							</td>
						</tr>
						<!-- <tr empty>
							<td colspan="1">조회된 항목이 없습니다.</td>
						</tr> -->
					</tbody>
				</table>
			</div>
			<div class="mb-10 mt-10 text-right">
				<button @click="saveAiConfigWarnWorkCctv" class="btn btn-labeled bg-primary">
					<b><i class="icon-checkmark3"></i></b>
					적용
				</button>
			</div>
		</div>
		<!-- // 주의 작업 -->
		<!-- 위험 작업 -->
		<div class="wrapper">
			<h5 class="panel-title" style="padding-bottom: 10px"><b>위험 작업 설정</b></h5>
			<div class="table-wrapper" danger>
				<table class="table table-hover">
					<thead>
						<tr>
							<th width="50px">대상물</th>
							<th width="120px" v-for="(row, index) in cctvInfo" :key="index">
								{{ row.cctvNm }}&nbsp;
								<input type="checkbox" :id="'danger_box' + row.cctvId" @input="checkValueCctvDangerAllCheckBox(row.cctvId, $event)" />
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(row, index) in aiConfigDangerCctv" :key="index">
							<td style="text-align: left">{{ row.objectNm }}</td>
							<td v-for="(r, i) in row.aiConfigCctvCheckVOs" :key="i">
								<input type="checkbox" v-model="r.checked" />
							</td>
						</tr>
						<!-- <tr empty>
							<td colspan="2">조회된 항목이 없습니다.</td>
						</tr> -->
					</tbody>
				</table>
			</div>
			<div class="mb-10 mt-10 text-right">
				<button @click="saveAiConfigDangerCctv" class="btn btn-labeled bg-primary">
					<b><i class="icon-checkmark3"></i></b>
					적용
				</button>
			</div>
		</div>
		<!-- // 위험 작업 -->
	</div>
	<!-- // 전체 -->
</template>

<script>
import Select2 from '@/components/Select2';
import apiIndex from '../../api/index';
import _ from 'lodash';

let axiosExtention;

const cctvAiConfigApi = apiIndex.cctvAiConfig;

export default {
	components: {
		Select2: Select2,
	},
	created() {
		this.pageParam = JSON.parse(sessionStorage.getItem('cctvAiConfigPageParam'));
		if (!this.pageParam) location.href = apiIndex.mainUrl;
		if (this.pageParam.loginUserRolecd !== '00') {
			this.isSiteDisabled = true;
		}
		//기본 현장 세팅
		// this.tempSearchSite = this.pageParam.siteOptions.filter(e => e.siteId == this.pageParam.loginUserSiteId)[0].siteNm;
		this.pageParam.siteOptions.forEach(e => {
			e.text = e.siteNm;
			e.value = e.siteId;
		});
		this.options.siteOptions = this.pageParam.siteOptions;
		// this.tempSearchSite = this.pageParam.loginUserSiteId;
		this.searchSite = this.pageParam.loginUserSiteId;
		this.cctvInfo = this.pageParam.inqCctvInfo;

		axiosExtention = this.$jarvisExtention.axiosExtention;
		this.searchSiteInfo();
	},
	mounted() {
		this.warnAndDangerWrapper = document.getElementsByClassName('wrapper');
	},
	data: () => ({
		apiUrl: {
			inqAiConfigCctvInfo: cctvAiConfigApi.inqAiConfigCctvInfo,
			inqAiConfigWarnWorkCctv: cctvAiConfigApi.inqAiConfigWarnWorkCctv,
			inqAiConfigDangerCctv: cctvAiConfigApi.inqAiConfigDangerCctv,
			saveAiConfigWarnWorkCctv: cctvAiConfigApi.saveAiConfigWarnWorkCctv,
			saveAiConfigDangerCctv: cctvAiConfigApi.saveAiConfigDangerCctv,
		},
		pageParam: null,
		//상세필드명맵
		detailsFieldMap: {
			otherSite: '복사할 현장',
			copySite: '복사될 현장',
			searchSite: '현장 조회',
			dangerImgCollectPeriodSec: '이미지 수집 기간',
			dangerJugeStandardSec: '위험(주의) 판단 발생 기준',
			warnSituateDuplicateJugeDiscgeMin: '주의 상황 중복 판단 기준',
			duplicateAlertDiscgeSec: '중복 알림 방지',
			aiDistigshSutbDgre: '민감도',
		},
		options: {
			siteOptions: '',
		},
		debug: false,
		// tempSearchSite: null,
		searchSite: null,
		cctvInfo: null,
		cctvInfoCount: 0,
		originalAiConfigWarnWorkCctv: [],
		// originalAiConfigDangerCctv: [],
		aiConfigWarnWorkCctv: [],
		aiConfigDangerCctv: [],
		warnAndDangerWrapper: [],
		changedWarnWorkCctv: [],
		isSiteDisabled: false,
		dangerObjectCount: 0,
		warnWorkObjectCount: 0,
	}),
	computed: {
		dangerCheckStatus() {
			var dangerCheckBoxValues = new Map();
			this.aiConfigDangerCctv.forEach(dangerCctv => {
				dangerCctv.aiConfigCctvCheckVOs.forEach(checkVO => {
					if (dangerCheckBoxValues[checkVO.cctvId] == undefined) {
						this.$set(dangerCheckBoxValues, checkVO.cctvId, new Array(checkVO.checked));
					} else {
						dangerCheckBoxValues[checkVO.cctvId].push(checkVO.checked);
					}
				});
			});
			return dangerCheckBoxValues;
		},
		warnCheckStatus() {
			var warnCheckBoxValues = new Map();
			this.aiConfigWarnWorkCctv.forEach(warnCctv => {
				warnCctv.aiConfigCctvCheckVOs.forEach(checkVO => {
					if (warnCheckBoxValues[checkVO.cctvId] == undefined) {
						this.$set(warnCheckBoxValues, checkVO.cctvId, new Array(checkVO.checked));
					} else {
						warnCheckBoxValues[checkVO.cctvId].push(checkVO.checked);
					}
				});
			});
			return warnCheckBoxValues;
		},
	},
	watch: {
		warnCheckStatus() {
			var warnCheckBoxValues = this.warnCheckStatus;
			if (warnCheckBoxValues.size != 0) {
				this.cctvInfo.forEach(cctv => {
					let allCheckBox = document.getElementById('warn_box' + cctv.cctvId);
					if (warnCheckBoxValues[cctv.cctvId].filter(booleanValues => booleanValues == true).length == this.warnWorkObjectCount) {
						allCheckBox.checked = true;
					} else {
						allCheckBox.checked = false;
					}
				});
			}
		},
		dangerCheckStatus() {
			var dangerCheckBoxValues = this.dangerCheckStatus;
			if (dangerCheckBoxValues.size != 0) {
				this.cctvInfo.forEach(cctv => {
					let allCheckBox = document.getElementById('danger_box' + cctv.cctvId);
					if (dangerCheckBoxValues[cctv.cctvId].filter(booleanValues => booleanValues == true).length == this.dangerObjectCount) {
						allCheckBox.checked = true;
					} else {
						allCheckBox.checked = false;
					}
				});
			}
		},
	},
	methods: {
		checkValueCctvWarnworkAllCheckBox(cctvId, event) {
			let allCheckBoxValue = event.target.checked;
			this.aiConfigWarnWorkCctv.forEach(warnCctv => {
				warnCctv.aiConfigCctvCheckVOs.forEach(checkVO => {
					if (checkVO.cctvId == cctvId) {
						checkVO.checked = allCheckBoxValue;
					}
				});
			});
		},
		checkValueCctvDangerAllCheckBox(cctvId, event) {
			let allCheckBoxValue = event.target.checked;
			this.aiConfigDangerCctv.forEach(dangerCctv => {
				dangerCctv.aiConfigCctvCheckVOs.forEach(checkVO => {
					if (checkVO.cctvId == cctvId) {
						checkVO.checked = allCheckBoxValue;
					}
				});
			});
		},
		checkItHaveCctvs() {
			if (this.cctvInfo == undefined || this.cctvInfo.length == 0) {
				setTimeout(() => window.alert('해당 현장에 CCTV가 없습니다.\nCCTV를 먼저 등록해주세요'), 500);
				Array.from(this.warnAndDangerWrapper).forEach(element => (element.hidden = true));
			} else {
				Array.from(this.warnAndDangerWrapper).forEach(element => (element.hidden = false));
			}
		},
		async getAiConfigInfo() {
			await this.$axios
				.post(this.apiUrl.inqAiConfigCctvInfo, { siteId: this.searchSite })
				.then(
					function (r) {
						this.cctvInfo = r.data;
						if (this.cctvInfo !== 'null') {
							this.cctvInfoCount = this.cctvInfo.length;
						} else {
							this.cctvInfo = null;
						}
						this.checkItHaveCctvs();
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
		},
		getAiConfigWarnWorkCctv() {
			this.$axios
				.post(this.apiUrl.inqAiConfigWarnWorkCctv, { siteId: this.searchSite })
				.then(
					function (r) {
						this.aiConfigWarnWorkCctv = r.data;
						this.warnWorkObjectCount = this.aiConfigWarnWorkCctv.length;
						this.changeAiConfigWarnWorkCctv();
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
		},
		getAiconfigDangerCctv() {
			this.$axios
				.post(this.apiUrl.inqAiConfigDangerCctv, { siteId: this.searchSite })
				.then(
					function (r) {
						this.aiConfigDangerCctv = r.data;
						this.dangerObjectCount = this.aiConfigDangerCctv.length;

						this.changeAiConfigDangerCctv();
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
		},
		// 현장의 설정 정보들 조회
		async searchSiteInfo() {
			if (!this.searchSite) {
				alert('현장을 선택해주세요.');
				return;
			}
			// this.searchSite = this.tempSearchSite;
			await this.getAiConfigInfo();
			this.getAiConfigWarnWorkCctv();
			this.getAiconfigDangerCctv();
		},
		// 값이 있으면 checked 변경
		changeAiConfigWarnWorkCctv() {
			if (!this.searchSite) {
				alert('현장을 선택해주세요.');
				return;
			}

			// this.searchSite = this.tempSearchSite;

			this.aiConfigWarnWorkCctv.forEach(e => {
				e.siteId = this.searchSite;

				e.aiConfigCctvCheckVOs = [];

				// 최초 모든 checked 값 cctv 개수에 맞춰 false로 등록
				for (let i = 0; i < this.cctvInfoCount; i++) {
					let checked = { cctvId: this.cctvInfo[i].cctvId, checked: false }; // 배열로 했더니 바인딩이 안되서 객체로 변경
					e.aiConfigCctvCheckVOs.push(checked);
				}
				// DB에 저장된 cctvId 가 존재하면
				if (e.cctvIds) {
					let cctvIds = e.cctvIds.split(',');
					cctvIds.forEach(cctvId => {
						for (let i = 0; i < this.cctvInfoCount; i++) {
							// cctvId와 cctv index 정보의 id가 같으면
							if (cctvId === this.cctvInfo[i].cctvId) {
								// 해당 자리 값 true로 변경
								e.aiConfigCctvCheckVOs[i].checked = true;
								break;
							}
						}
					});
				}
			});

			// deep copy
			// $.extend(true, this.originalAiConfigWarnWorkCctv, this.aiConfigWarnWorkCctv);
			this.originalAiConfigWarnWorkCctv = _.cloneDeep(this.aiConfigWarnWorkCctv);
		},
		// 값이 있으면 checked 변경
		changeAiConfigDangerCctv() {
			if (!this.searchSite) {
				alert('현장을 선택해주세요.');
				return;
			}
			// this.searchSite = this.tempSearchSite;

			this.aiConfigDangerCctv.forEach(e => {
				e.siteId = this.searchSite;

				e.aiConfigCctvCheckVOs = [];

				// 최초 모든 checked 값 cctv 개수에 맞춰 false로 등록
				for (let i = 0; i < this.cctvInfoCount; i++) {
					let checked = { cctvId: this.cctvInfo[i].cctvId, checked: false }; // 배열로 했더니 바인딩이 안되서 객체로 변경
					e.aiConfigCctvCheckVOs.push(checked);
				}

				// DB에 저장된 cctvId 가 존재하면
				if (e.cctvIds) {
					let cctvIds = e.cctvIds.split(',');
					cctvIds.forEach(cctvId => {
						for (let i = 0; i < this.cctvInfoCount; i++) {
							// cctvId와 cctv index 정보의 id가 같으면
							if (cctvId === this.cctvInfo[i].cctvId) {
								// 해당 자리 값 true로 변경
								e.aiConfigCctvCheckVOs[i].checked = true;
								break;
							}
						}
					});
				}
			});
			// deep copy
			// this.originalAiConfigDangerCctv = _.cloneDeep(this.aiConfigDangerCctv);
		},
		saveAiConfigWarnWorkCctv() {
			if (confirm('적용하시겠습니까?')) {
				const insAiConfigWarnWorkCctvWrapperInVO = {};

				insAiConfigWarnWorkCctvWrapperInVO.insAiConfigWarnWorkCctvInVOs = this.aiConfigWarnWorkCctv;
				insAiConfigWarnWorkCctvWrapperInVO.originalInsAiConfigWarnWorkCctvInVOs = this.originalAiConfigWarnWorkCctv;
				this.changedWarnWorkCctv = [];
				this.$axios
					.post(this.apiUrl.saveAiConfigWarnWorkCctv, insAiConfigWarnWorkCctvWrapperInVO)
					.then(
						function () {
							this.getAiConfigWarnWorkCctv();
							alert('적용되었습니다.');
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
			}
		},
		saveAiConfigDangerCctv() {
			if (confirm('적용하시겠습니까?')) {
				const insAiConfigDangerCctvWrapperInVO = {};

				insAiConfigDangerCctvWrapperInVO.insAiConfigDangerCctvInVOs = this.aiConfigDangerCctv;
				// insAiConfigDangerCctvWrapperInVO.originalInsAiConfigDangerCctvInVOs = this.originalAiConfigDangerCctv;
				this.$axios
					.post(this.apiUrl.saveAiConfigDangerCctv, insAiConfigDangerCctvWrapperInVO)
					.then(
						function () {
							// 성공하면 다시 조회
							this.getAiconfigDangerCctv();
							alert('적용되었습니다.');
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
			}
		},
	},
};
</script>

<style scoped>
.content {
	padding-bottom: 20px;
}

[v-cloak] > * {
	display: none !important;
}

#rootVM > .panel .panel-body .form-group {
	display: flex;
	align-items: center;
}
#rootVM > .panel .panel-body .form-group label {
	width: 60%;
	margin-top: 5px;
	max-width: 130px;
}

.row .ai-config-all {
	display: flex;
	float: left;
	flex: 1;
	justify-content: center;
	align-items: center;
}
.ai-config-all > label {
	margin-top: 8px;
}
.ai-config-all > input {
	margin-left: 20px;
	margin-right: 10px;
	width: 48px;
	text-align: center;
}

/* table */
.table-wrapper {
	border-collapse: collapse;
	overflow-x: auto;
	overflow-y: auto;
	border-color: #dedee0;
	border-style: solid;
	border-width: 1px 1px 1px 1px;
	position: relative;
	color: #515967;

	background-color: #fff;
}

[warnWork] {
	height: 200px;
}
[danger] {
	height: 290px;
}

.table {
	table-layout: fixed;
}
/* table head */
.table-wrapper .table > thead > tr > th {
	background-color: #f5f5f5;

	text-align: center;

	padding: 0.5em 0.6em 0.4em 0.6em;
	font-weight: 700;
	white-space: nowrap;
	text-overflow: ellipsis;

	border-color: #dedee0;
	border-style: solid;
	border-width: 0 0 0 1px;

	overflow: auto;
	position: sticky;
	top: 0;
	box-shadow: inset 0 -1px 0 #dedee0;
}

.table-wrapper .table > thead > tr > th:first-child {
	border-width: 0 0 0 0;
}

/* table body */
/* 홀수 */
.table-wrapper .table > tbody > tr:nth-child(2n + 1) {
	background-color: #fff;
}
/* 짝수 */
.table-wrapper .table > tbody > tr:nth-child(2n) {
	background-color: #fbfbfb;
}

.table-wrapper .table > tbody > tr:hover {
	background-color: #b5bdcb;
	background-image: url(/lib/kendo-r2-2019/styles/textures/highlight.png);
	background-position: 50% 50%;
}

.table-wrapper .table > tbody > tr > td:first-child {
	border-width: 0 0 0 0;
}

.table-wrapper .table > tbody > tr > td {
	border-color: #dedee0;
	border-style: solid;
	border-width: 0 0 0 1px;
	padding: 0.4em 0.6em;
	overflow: hidden;
	line-height: 1.6em;
	vertical-align: middle;
	text-overflow: ellipsis;
}

.table-wrapper .table > tbody > tr > td {
	text-align: center;
}

/* table empty */
.table [empty] {
	display: none;
	height: 166px;
}
.table [empty] td {
	padding: 30px;
	font-size: 1.2em;
	text-align: center;
}
.table [empty]:first-child:last-child {
	display: table-row;
}
</style>
